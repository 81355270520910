<template>
  <uploader backend />
</template>

<script>
export default {
  name: 'uploadBackend',
  components: {
    uploader: () => import('@/components/uploader.vue'),
  },
};
</script>
